import { render, staticRenderFns } from "./goodsLeaveMessage.vue?vue&type=template&id=23a2bf79&scoped=true"
import script from "./goodsLeaveMessage.vue?vue&type=script&lang=js"
export * from "./goodsLeaveMessage.vue?vue&type=script&lang=js"
import style0 from "./goodsLeaveMessage.vue?vue&type=style&index=0&id=23a2bf79&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a2bf79",
  null
  
)

export default component.exports